@import '../../../../base'

.accounting-transactions
  display: flex
  flex-wrap: wrap
  div[class^="accounting-transaction-item-"]
    border-width: 2px
    border-style: solid
    border-radius: 3px
    width: 400px
    margin: 0 8px 8px 0
    line-height: 12px
    > div:first-of-type
      @include flex
      color: $primary-color
      padding: 0 10px
      height: 42px
      border-bottom: 1px solid #fff
      box-shadow: 0 1px #d9e5f0, inset 0 -1px #d9e5f0
      svg
        width: 20px
        margin-right: 10px

.accounting-transaction-item-xrp
  border-color: #7bc3e6

.accounting-transaction-item-doge
  border-color: #cec48f

.accounting-transaction-item-btc
  border-color: #ecbd83

.accounting-transaction-item-ltc
  border-color: #76ABDB

.accounting-transaction-item-trx
  border-color: #e77a8b

.accounting-transaction-item-bch
  border-color: #b8d69f

.accounting-transaction-item-eos
  border-color: #b0cde5

.accounting-transaction-item-eth
  border-color: #83a6e8

.accounting-transaction-coin
  display: flex
  flex-direction: column
  justify-content: center
  margin-right: auto
  .currency-container
    font-size: 14px!important
    line-height: 14px
    margin-bottom: 2px

.accounting-transaction-amount
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-end
  span
    color: rgba(#566e8e, .9)
    margin-top: 2px

.accounting-transaction-table
  padding: 0 10px
  label
    color: #566e8e
    margin-right: auto
  > div
    @include flex
    &:not(:last-of-type):not(:nth-child(4))
      @include horizontalSeparator
      height: auto
    > div:last-of-type
      width: 130px
      max-width: 130px
      text-align: right
      position: relative
      margin-left: 6px
      &:before
        content: ""
        @include verticalSeparator(14px)
        position: absolute
        left: 0
        margin-top: -1px

.accounting-transaction-table-header
  color: #38a3d4
  @include bold
  text-transform: uppercase
  height: 24px!important
  > div:first-of-type
    flex: 1
    text-align: right

.accounting-transaction-table-row
  height: 40px!important
  padding: 6px 0
  > div
    display: flex
    flex-direction: column
    align-items: flex-end
    font-size: 13px
    color: $primary-color
    strong
      color: #6d809b
      margin-top: 2px

  > div:last-of-type:before
    height: 26px!important
  .currency-container
    margin: 0
  &:last-of-type
    background: #f0f7fd
    margin: 0 -10px
    padding: 6px 10px
    border-top: 2px solid #d9e5f0

.accounting-transactions-timezone
  width: 350px
  margin: 6px 0 12px

.accounting-transactions-filter
  @include flex
  align-items: center
  margin: 6px 0 12px
  .accounting-transactions-timezone
    margin: 0px